import { isOffline, OFFLINE_FILE_BUCKET } from 'helpers/constants';
import { fixSlash } from 'utils/querystring';

const HTTPS_PREFIX = 'https:';

function fixProtocol(url = '') {
	if (window.location.protocol === HTTPS_PREFIX) {
		return url.trim().replace(/^http:/i, HTTPS_PREFIX);
	} else {
		return url;
	}
}

const S3_OFFLINE_URL = `http://localhost:4569/${OFFLINE_FILE_BUCKET}`;
const FS_OFFLINE_REGEX = new RegExp(`^https://${OFFLINE_FILE_BUCKET}(.+)`);

export const fixContentUrlForOffline = isOffline
	? (url) => {
			if (url) {
				const match = FS_OFFLINE_REGEX.exec(url);
				if (match) {
					return S3_OFFLINE_URL + match[1];
				}
			}
			return url;
	  }
	: (url) => url;

// Returns string for text/markdown, 'any' for parsed JSON.
export function getFile({ url, type = 'text' }: { url: string; type?: string }): Promise<string | any> {
	return fetch(fixProtocol(fixSlash(fixContentUrlForOffline(url)))).then((response) => {
		if (!response.ok) {
			throw new Error(response.statusText + ' (' + response.status + ')');
		}
		switch (type) {
			case 'json':
				return response.json();
			case 'text':
			case 'markdown':
			default:
				return response.text();
		}
	});
}
